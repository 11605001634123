import { useGlobalStore } from "@/lib/state/globalStore";
import { cn } from "@/lib/utils";

function NotificationDot() {
    const conn_status = useGlobalStore((state) => state.connection.status);
    const unread = !useGlobalStore((state) => state.get_allMessagesSeen());
    const connected = conn_status === "Up";
    const sym = unread ? "!" : "";

    return (
        <div
            className={cn(
                "tw-absolute -tw-top-3.5 tw-right-1 tw-h-fit tw-w-fit tw-rounded-full tw-bg-white tw-p-0.5",
                {
                    "tw-animate-bounce": unread,
                    "tw-hidden": !unread && !connected,
                },
            )}
        >
            <div
                className={cn(
                    "tw-h-[20px] tw-w-[20px] tw-rounded-full tw-font-normal",
                    {
                        "tw-bg-dpj-copper-orange": unread,
                        "tw-animate-pulse tw-bg-dpj-forest-green": !unread,
                    },
                )}
            >
                {sym}
            </div>
        </div>
    );
}

export { NotificationDot };
