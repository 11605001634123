export default function SpeachBubble({ size = 25 }: { size?: number }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 35.419 35.419"
        >
            <g id="Chatt-icon" transform="translate(1 1)">
                <path
                    id="message-circle"
                    d="M36.419,18.781a15.559,15.559,0,0,1-1.671,7.055,15.781,15.781,0,0,1-14.11,8.726,15.558,15.558,0,0,1-7.055-1.671L3,36.419,6.528,25.836a15.558,15.558,0,0,1-1.671-7.055,15.781,15.781,0,0,1,8.726-14.11A15.558,15.558,0,0,1,20.638,3h.928A15.744,15.744,0,0,1,36.419,17.853Z"
                    transform="translate(-3 -3)"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                />
                <g id="more-horizontal" transform="translate(12.532 15.746)">
                    <circle
                        id="Ellipse_94"
                        data-name="Ellipse 94"
                        cx="0.643"
                        cy="0.643"
                        r="0.643"
                        transform="translate(4.499 0)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                    />
                    <circle
                        id="Ellipse_95"
                        data-name="Ellipse 95"
                        cx="0.643"
                        cy="0.643"
                        r="0.643"
                        transform="translate(8.997 0)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                    />
                    <circle
                        id="Ellipse_96"
                        data-name="Ellipse 96"
                        cx="0.643"
                        cy="0.643"
                        r="0.643"
                        transform="translate(0 0)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                    />
                </g>
            </g>
        </svg>
    );
}
