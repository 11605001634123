import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import type { } from '@redux-devtools/extension' // required for devtools typing
import { MessagesSlice, createMessagesSlice } from "@/lib/state/messagesSlice"
import { WidgetStateSlice, createWidgetStateSlice } from "@/lib/state/widgetStateSlice"
import { ProactiveSlice, createProactiveSlice } from './proactivesSlice'


// TODO: Consider breaking this up into a persistent store and a non-persistent store
const useGlobalStore = create<WidgetStateSlice & MessagesSlice & ProactiveSlice>()(
    devtools(
        persist(
            (...a) => ({
                ...createWidgetStateSlice(...a),
                ...createMessagesSlice(...a),
                ...createProactiveSlice(...a),
            }), {
            name: "dpj_widget", version: 0, //TODO: handle versoin upgrade
            partialize: (state) => (
                {
                    context: state.context,
                    latestMessageId: state.latestMessageId,
                    latestMessageSeenId: state.latestMessageSeenId,
                    open: state.open,
                    input: state.input,
                    proactives: state.proactives,
                    startedFromProactive: state.startedFromProactive,
                }

            ), // TODO: chose what to persist

        })
    )
)

export { useGlobalStore }
