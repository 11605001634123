import { cn } from "@/lib/utils";
import { useState } from "react";
import logoUrl from "@/../images/logo.png";

type imageState = "loading" | "ok" | "fallback" | "initials";

function Avatar({
    className,
    alt = "dpj chat agent",
    initials = "dpj",
    src,
}: {
    // children: ReactNode;
    className?: string;
    alt?: string;
    initials?: string;
    src?: string;
}) {
    const [imageState, imageState_set] = useState("loading" as imageState);

    return (
        <div
            className={cn("avatar tw-h-14 tw-w-14", className)}
            data-state={imageState}
        >
            <div className="tw-flex tw-h-full tw-w-full tw-rounded-full">
                <span
                    className={cn(
                        "tw-inline-flex tw-h-full tw-w-full tw-items-center tw-justify-center tw-rounded-full tw-bg-dpj-forest-green/70",
                        {
                            "tw-hidden":
                                imageState !== "initials" &&
                                imageState !== "loading",
                        },
                    )}
                >
                    <span className="tw-text-lg tw-font-medium tw-leading-none tw-text-white">
                        {initials}
                    </span>
                </span>
                <img
                    onLoad={() => {
                        console.log("onLoad", imageState);
                        if (imageState === "loading") {
                            imageState_set("ok");
                        }
                    }}
                    onError={() => {
                        console.log("onError", imageState);
                        if (imageState === "loading") {
                            imageState_set("fallback");
                        } else if (imageState === "fallback") {
                            imageState_set("initials");
                        }
                    }}
                    alt={alt}
                    src={
                        imageState === "loading" || imageState === "ok"
                            ? src
                            : logoUrl
                    }
                    className={cn(
                        "tw-h-full tw-w-full tw-rounded-full tw-object-cover tw-object-center",
                        {
                            "tw-hidden":
                                imageState !== "ok" &&
                                imageState !== "fallback",
                        },
                    )}
                />
            </div>
        </div>
    );
}

export { Avatar };
