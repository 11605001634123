import { ReactNode, SyntheticEvent } from "react";
import pngLogoUrl from "@/../images/logo.png";
import { localization as l } from "@/lib/localization";

function Skeleton({
    children,
    className,
}: {
    children?: ReactNode;
    className?: string;
}) {
    return (
        <div
            className={`tw-animate-pulse tw-overflow-clip tw-bg-dpj-forest-green/15 ${className}`}
        >
            {children}
        </div>
    );
}

function Message({ variant }: { variant?: "sending" | "agentTyping" }) {
    function imgOnError(e: SyntheticEvent<HTMLImageElement, Event>) {
        const parent = e.currentTarget.parentElement;
        const initials = document.createElement("span");
        initials.className =
            "tw-inline-flex tw-items-center tw-justify-center tw-rounded-full";
        initials.innerHTML =
            '<span class="tw-text-lg tw-font-medium  tw-leading-none tw-text-white">dpj</span>';
        e.currentTarget.remove();
        parent?.appendChild(initials);
    }
    if (variant === "sending") {
        return (
            <>
                <div className="tw-animate-pulse tw-pl-8 tw-pt-3 tw-text-end tw-text-sm tw-text-dpj-industrial-grey">
                    {/* <Skeleton className="tw-mt-2 tw-flex tw-h-2 tw-w-full tw-gap-2 tw-overflow-clip tw-rounded tw-pt-[13px] tw-text-sm"> */}
                    {l["Sending"]}
                    {/* </Skeleton> */}
                    {/* <div className="tw-w-full tw-text-end tw-text-xs tw-text-gray-400">
                        --:--
                    </div> */}
                </div>
            </>
        );
    } else {
        return (
            <div className="tw-pr-8">
                <Skeleton className="tw-mt-2 tw-flex tw-h-20 tw-w-full tw-gap-2 tw-overflow-clip tw-rounded tw-px-2 tw-py-3 tw-pl-[10px] tw-pr-8 tw-pt-[13px] tw-text-sm">
                    <div className="tw-flex tw-h-14 tw-w-14 tw-items-center tw-justify-center tw-rounded-full tw-bg-dpj-forest-green/15 tw-text-lg tw-font-bold tw-text-dpj-forest-green">
                        <img
                            src={pngLogoUrl}
                            alt="dpj logo"
                            onError={imgOnError}
                        />
                    </div>
                    <div className="tw-flex tw-w-full tw-flex-1 tw-flex-col tw-gap-3">
                        <div className="tw-h-3 tw-w-1/3 tw-bg-dpj-forest-green/15" />
                        <div className="tw-flex tw-w-full tw-flex-col tw-justify-between tw-gap-1">
                            <div className="tw-h-2 tw-w-3/4 tw-bg-dpj-forest-green/15" />
                            <div className="tw-h-2 tw-w-2/4 tw-bg-dpj-forest-green/15" />
                        </div>
                    </div>
                </Skeleton>
                <div className="tw-w-fit tw-text-xs tw-text-gray-400">
                    --:--
                </div>
            </div>
        );
    }
}

export { Message as MessageSkeleton };
