import { StateCreator } from 'zustand'
import { WidgetStateSlice } from './widgetStateSlice'
import { useGlobalStore } from './globalStore';


// STATE
const gracePeriodMs = 5_000
const openDelayMs = 1_000
interface ProactiveSlice {
    proactives: Array<Proactive> // TODO: consider using Map to make duplicate ids impossible
    currentProactive: Proactive | null
    proactiveSetTime: number
    proactiveGraceperiodStart: number
    proactiveShouldOpen: boolean

    proactivesLoad: () => void
    proactiveOnTick: () => void
    proactiveDismiss: () => void
    proactiveInGracePeriod: () => boolean
}
const createProactiveSlice: StateCreator<
    ProactiveSlice & WidgetStateSlice,
    [],
    [],
    ProactiveSlice
> = (set, get) => (
    {
        proactives: [],
        currentProactive: null,
        proactiveSetTime: 0,
        proactiveGraceperiodStart: Date.now(),
        proactiveShouldOpen: false,
        proactiveOnTick: () => {
            const state = get();
            set((state) => ({ ...state, proactiveShouldOpen: !!get().currentProactive && !get().proactiveInGracePeriod() && !!get().proactiveSetTime && get().proactiveSetTime + openDelayMs <= Date.now() }));
            if (state.connection.status === "Up" || state.open !== "closed" || get().proactiveInGracePeriod()) {
                return;
            }
            DEV: console.log("[ProactiveSlice] tick action");
            const proactives = state.proactives
            const next = proactives.find((p) => {
                const now = Date.now();
                if (p.dismissedDate === 0) {
                    const timeout = p.timeoutInitialMin;
                    DEV: console.log("[ProactiveSlice] timeoutMs", timeout * 60 * 1000,
                        "\n(now - p.registeredDate)", (now - p.registeredDate),
                        "\n((timeout * 60 * 1000) <  (now - p.registeredDate))", (timeout * 60 * 1000) < (now - p.registeredDate)
                    );
                    return (timeout * 60 * 1000) < (now - p.registeredDate);
                } else {
                    const timeout = p.timeoutDismissedMin;
                    DEV: console.log("[ProactiveSlice] timeoutMs", timeout * 60 * 1000,
                        "\n(now - p.dismissedDate)", (now - p.dismissedDate),
                        "\n((timeout * 60 * 1000) <  (now - p.dismissedDate))", (timeout * 60 * 1000) < (now - p.dismissedDate)
                    );
                    return (timeout * 60 * 1000) < (now - p.dismissedDate);
                }
            });
            if (!next) {
                return null;
            }
            useGlobalStore.setState({ currentProactive: next, open: "proactive", proactiveSetTime: Date.now() });

        },
        proactivesLoad: () => {
            const proactives = loadProactives(get().proactives);
            set((state) => ({ ...state, proactives, currentProactive: null, proactiveGraceperiodStart: Date.now() }));
        },
        proactiveDismiss: () => {
            const id = get().currentProactive?.id;
            if (!id) {
                console.warn("[proactiveSlice.ts] dismissed failed, no current proactive to dismiss");
                return;
            }
            const proactives = get().proactives
            const p = proactives.find((p) => p.id === id);
            const newOpenState = get().open === "proactive" ? "closed" : get().open;
            if (!p) {
                throw new Error(`Proactive with id ${id} not found`);
            }
            p.dismissedDate = Date.now();
            set((state) => ({
                ...state, open: newOpenState,
                proactiveShouldOpen: false,
                proactiveSetTime: 0,
                proactives,
            }));
            setTimeout(() => {
                set((state) => ({
                    ...state,
                    currentProactive: null,
                    proactiveGraceperiodStart: Date.now()
                }));
            }, openDelayMs);
        },
        proactiveInGracePeriod: () => {
            return get().proactiveGraceperiodStart + gracePeriodMs > Date.now();
        },

    }
)

function loadProactives(loaded: Array<Proactive>): Array<Proactive> {
    const proactives: Array<Proactive> = loaded;
    if (Object.keys(window).includes("dpj") &&
        // @ts-expect-error undefined
        Object.keys(window.dpj).includes("widget") &&
        // @ts-expect-error undefined
        Object.keys(window.dpj.widget).includes("proactives")) {

        // @ts-expect-error undefined
        const fromWindow = window.dpj.widget.proactives as Array<ProactiveRaw>;
        fromWindow
            .filter((fw) => {
                const res = !loaded.some((p) => p.id === fw.id)
                console.warn("proactives, id exists", fw.id, res);
                return res;
            })
            .forEach((fw) => {
                if (!checkRaw(fw)) {
                    console.error("Invalid proactive", fw);
                    return;
                }
                proactives.push({ ...fw, registeredDate: Date.now(), dismissedDate: 0 });
            });
        return proactives;
    }
    return [];
}

const checkRaw = (raw: ProactiveRaw): boolean => {
    if (
        !("type" in raw) ||
        !("id" in raw) ||
        !("timeoutInitialMin" in raw) ||
        !("timeoutDismissedMin" in raw)
    ) {
        return false;
    }
    if (raw.type === "chat") {
        return "text" in raw &&
            "ctaText" in raw &&
            "avatarUrl" in raw &&
            "avatarAlt" in raw &&
            "displayName" in raw &&
            "inChatMessage" in raw;
    } else if (raw.type === "link") {
        return "title" in raw &&
            "text" in raw &&
            "ctaUrl" in raw;
    }
    return false;
}

// TYPES
type Base = {
    id: string;
    timeoutInitialMin: number;
    timeoutDismissedMin: number | 0; // 0 means never show again;
};
type Registered = {
    registeredDate: number;
    dismissedDate: number | 0; // 0 means not yet dismissed,
};

type Default = Base &
    Registered & {
        type: "default";
    };

type Chat = Base & {
    type: "chat";
    text: string;
    ctaText: string;
    inChatMessage: string;
    avatarUrl: string;
    displayName: string;
    avatarAlt: string;
};

type Link = Base & {
    type: "link";
    title: string;
    text: string;
    ctaText: string;
    ctaUrl: string;
};

type ProactiveRaw = Chat | Link | Default;

type Proactive = ProactiveRaw & Registered;


export type { ProactiveSlice, Proactive }
export { createProactiveSlice }
