import { StateCreator } from 'zustand'
import { Message } from '../types'
import { WidgetStateSlice } from './widgetStateSlice'
// import { chatScrollToEnd } from '../utils'



interface MessagesSlice {
    messages: Array<Message>
    set_messages: (messages: Array<Message>) => void
}
const createMessagesSlice: StateCreator<
    MessagesSlice & WidgetStateSlice,
    [],
    [],
    MessagesSlice
> = (set) => (
    {
        messages: [],
        set_messages: (messages: Array<Message>) => {
            // chatScrollToEnd()
            set((state) => ({ ...state, messages, agentTyping: false }))
        },
    }
)
export type { MessagesSlice }
export { createMessagesSlice }
