import { cn } from "@/lib/utils";
import { localization as l } from "@/lib/localization";
import { useGlobalStore } from "@/lib/state/globalStore";

function Timestamp({ date, className }: { date: Date; className?: string }) {
    const now = useGlobalStore((state) => state.now);
    const diff = now - date.getTime();
    const min = 60_000;

    let txt = "";
    if (diff < 10_000) {
        txt = `${l["Now"]}`;
    } else if (diff < min) {
        txt = `${l["Less than a minute ago"]}`;
    } else if (diff < 2 * min) {
        txt = `${l["A minute ago"]}`;
    } else if (diff < 5 * min) {
        txt = `${l["[x] minutes ago"].replace("[x]", Math.floor(diff / min).toString())}`;
    } else {
        txt = `${date.getHours().toString().padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}`;
    }

    return (
        <div
            data-diff={diff}
            data-now={now}
            data-date={date}
            className={cn("timestamp tw-text-xs tw-text-gray-400", className)}
        >
            {txt}
        </div>
    );
}

export { Timestamp };
