// console.log("chatwidget: init.ts");

// @ts-expect-error undefined var
if (typeof dpj_runtime === "undefined") {
    // @ts-expect-error undefined var
    window.dpj_runtime = "development";
    console.warn("dpj_runtime not set, defaulting to 'development'");
}


// Webpack sets this global variable to the window object. A dependency to the sdk relies on this so we set it ourselves before importing anything else.
window.global ||= window;
