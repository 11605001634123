import SpeachBubble from "../icons/SpeachBubble";
import { useGlobalStore } from "@/lib/state/globalStore";
import act from "@/lib/sdkWrangler";
import { NotificationDot } from "@/components/ui/notificationDot";
import { OmnichannelChatSDK } from "@microsoft/omnichannel-chat-sdk";

type Props = {
    chatSDK: OmnichannelChatSDK;
};

function Dot({ chatSDK }: Props): JSX.Element {
    const set_open = useGlobalStore((state) => state.set_open);
    const proactiveDismiss = useGlobalStore((state) => state.proactiveDismiss);
    const set_allMessagesSeen = useGlobalStore(
        (state) => state.set_allMessagesSeen,
    );

    function openChat() {
        proactiveDismiss();
        set_open("chat");
        set_allMessagesSeen();
        if (
            useGlobalStore.getState().context &&
            useGlobalStore.getState().connection.status === "Down"
        ) {
            act.connect(chatSDK);
        }
    }

    return (
        <button
            type="button"
            className="tw-fixed tw-bottom-[15px] tw-right-[15px] tw-z-20 tw-h-[60px] tw-w-[60px] tw-rounded-full tw-bg-dpj-forest-green tw-text-sm tw-font-semibold tw-text-white tw-shadow hover:tw-bg-dpj-forest-green/90 focus-visible:tw-outline focus-visible:tw-outline-2 focus-visible:tw-outline-offset-2 focus-visible:tw-outline-dpj-forest-green sm:tw-bottom-4 sm:tw-right-4"
            onClick={openChat}
        >
            <NotificationDot />
            <SpeachBubble size={30} />
        </button>
    );
}

export default Dot;
