// import Header from "./components/Header";
import Messages from "./Messages";
// import Input from "./Input";
import { useState } from "react";
import Cross from "@/icons/Cross";
import MessageInput from "./MessageInput";
import { MinusIcon } from "@radix-ui/react-icons";
import { Button } from "@/components/ui/button";
import { localization as l } from "@/lib/localization";
import { useGlobalStore } from "@/lib/state/globalStore";
import { useShallow } from "zustand/react/shallow";
import { OmnichannelChatSDK } from "@microsoft/omnichannel-chat-sdk";
import act from "@/lib/sdkWrangler";

// chatSDK,
// state_conn,
// state_send,
// state_chatCtx,
// state_msgs,
// state_input,
// state_agentActive,
// isOpen,
// action_close,
// action_end,
type Props = {
    chatSDK: OmnichannelChatSDK;
};

function ChatContainer({ chatSDK }: Props): JSX.Element {
    const state_confirmEndChat = useState(false);
    const { open, set_open, conn_stat } = useGlobalStore(
        useShallow((state) => ({
            open: state.open,
            set_open: state.set_open,
            conn_stat: state.connection.status,
        })),
    );
    const toggleConfirmEnd = () => {
        if (conn_stat === "Up") {
            state_confirmEndChat[1](!state_confirmEndChat[0]);
        } else {
            minimize();
        }
    };
    const endChat = () => {
        act.disconnect(chatSDK);

        toggleConfirmEnd();
    };
    const minimize = () => {
        set_open("closed");
    };
    let mainArea = <></>;
    if (state_confirmEndChat[0]) {
        mainArea = (
            <>
                <div className="tw-fixed tw-h-[calc(100svh-11rem)] tw-w-full tw-content-center tw-bg-black/20 tw-p-5 sm:tw-h-96">
                    <div className="dialog tw-bg-whi tw-flex tw-h-fit tw-w-full tw-flex-col tw-items-center tw-justify-start tw-gap-4 tw-rounded tw-bg-white tw-p-8 tw-pb-1 tw-text-center tw-text-lg tw-font-semibold tw-shadow">
                        <div>{l["Do you want to end this chat?"]}</div>
                        <div className="tw-flex tw-h-fit tw-w-fit tw-flex-col tw-content-between tw-justify-between tw-gap-2">
                            <span className="tw-space-x-8">
                                <Button
                                    title={l["Confirm end chat"]}
                                    className="tw-h-11 tw-w-28 tw-text-lg"
                                    variant="destructive"
                                    onClick={endChat}
                                >
                                    {l["Yes"]}
                                </Button>
                                <Button
                                    title={l["Go back to the chat"]}
                                    className="tw-h-11 tw-w-28 tw-text-lg"
                                    onClick={toggleConfirmEnd}
                                >
                                    {l["No"]}
                                </Button>
                            </span>
                        </div>
                        {/* <Button
                            variant="link"
                            title="Ladda ner konverstionen"
                            className=""
                            onClick={() => {
                                throw new Error(
                                    "TODO: Download transcript not implemented",
                                );
                            }}
                        >
                            {l["Download transcript"]}
                        </Button> */}
                    </div>
                </div>
                <div className="tw-h-[calc(100svh-11rem)] sm:tw-h-96"></div>
                <div className="tw-fixed tw-h-28 tw-w-full tw-bg-black/20 sm:tw-h-20"></div>
                <MessageInput
                    chatSDK={chatSDK}
                    className="tw-pointer-events-none"
                />
            </>
        );
    } else {
        mainArea = (
            <>
                <div className="tw-h-[calc(100svh-11rem)] sm:tw-h-96">
                    <Messages chatSDK={chatSDK} />
                </div>
                <MessageInput chatSDK={chatSDK} className="" />
            </>
        );
    }

    return (
        <div className="chat-main tw-pointer-events-none tw-fixed tw-bottom-2 tw-right-2 tw-z-[1001] tw-w-full tw-max-w-[calc(100svw-24px)] sm:tw-bottom-4 sm:tw-right-[15px] sm:tw-w-[370px]">
            <div
                className="chat-card data-[state=open]:tw-duration-750 tw-h-full tw-w-full tw-rounded tw-bg-white tw-opacity-0 tw-transition tw-duration-300 tw-ease-out data-[state=open]:tw-pointer-events-auto data-[state=closed]:tw-translate-y-12 data-[state=open]:tw-translate-y-0 data-[state=open]:tw-opacity-100 data-[state=open]:tw-shadow-2xl data-[state=open]:tw-fade-in"
                data-state={open === "chat" ? "open" : "closed"}
            >
                <div className="tw-flex tw-h-12 tw-items-center tw-rounded-t tw-bg-dpj-forest-green tw-pl-5 tw-pr-1 tw-text-lg tw-font-bold tw-text-white">
                    <div>{l["Chat with DPJ"]}</div>
                    <div
                        onClick={minimize}
                        className="tw-ml-auto tw-h-12 tw-w-10 tw-cursor-pointer tw-p-2 tw-pr-0 sm:tw-w-6"
                    >
                        <div className="tw-sr-only">{l["Minimize"]}</div>
                        <MinusIcon className="tw-h-full tw-w-full" />
                    </div>
                    <div
                        onClick={toggleConfirmEnd}
                        className="tw-h-12 tw-w-12 tw-cursor-pointer tw-p-2 sm:tw-w-8"
                    >
                        <div className="tw-sr-only">{l["Close"]}</div>
                        <Cross className="tw-h-full tw-w-full" />
                    </div>
                </div>
                <>{mainArea}</>
            </div>
        </div>
    );
}
export default ChatContainer;
